import React, { useEffect, useState } from 'react';
import styles from './carousel.module.scss';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'next/image';
import Link from 'next/link';

type ImageMedia = {
  file: string;
};

type Target = {
  pageFolder: {
    _url: string;
  };
};

type DataItem = {
  image: {
    media: ImageMedia;
  };
  target: Target;
};

type CarouselB2bProps = {
  data: DataItem[];
};

const imageLoader = ({ src }: { src: string }) => {
  return `${src}`;
};

const CarouselB2b: React.FC<CarouselB2bProps> = ({ data }) => {
  const [carouselData, setCarouselData] = useState<DataItem[]>([]);

  useEffect(() => {
    if (data.length > 0) {
      setCarouselData(data);
    }
  }, [data]);

  return (
    <>
      <div className={styles['container']}>
        <Carousel
          style={{ width: '100%' }}
          prevIcon={<img src="/arrowLeft.svg" alt="Custom Prev Icon" />}
          nextIcon={<img src="/arrowRight.svg" alt="Custom Next Icon" />}
        >
          {carouselData.length > 0 ? (
            carouselData.map((item, index) => (
              <Carousel.Item key={index}>
                <Link href={item.target.pageFolder._url} passHref>
                  <a rel="noopener noreferrer">
                    <div className={styles['img-carousel']}>
                      <div className={styles['img-text']}></div>
                      <Image 
                        src={item.image.media.file} 
                        width={504} 
                        height={201} 
                        alt={`Banner ${index + 1}`} 
                        loading='lazy' 
                        loader={imageLoader} 
                      />
                    </div>
                  </a>
                </Link>
              </Carousel.Item>
            ))
          ) : (
            <Carousel.Item>
              <div className={styles['img-carousel']}>
                <div className={styles['img-text']}></div>
                <Image 
                  src={"https://res.cloudinary.com/dlwdq84ig/image/upload/v1730567811/ztbdvveoubbz46xyhqku.webp"} 
                  width={504} 
                  height={201} 
                  alt="Default Banner" 
                  loading='lazy' 
                  loader={imageLoader} 
                />
              </div>
            </Carousel.Item>
          )}
          
          {/* Código Anterior */}
         {/* <div className={styles['container']}>
        <Carousel
        style={{ width: '100%'}}
        prevIcon={<img src="/arrowLeft.svg"
        alt="Custom Prev Icon" />}
        nextIcon={<img src="/arrowRight.svg" alt="Custom Next Icon" />}
        >
          {/* <Carousel.Item>
            <Link href={CONST_REDIRECTS.ANNOUNCEMENTS}>
                <div className={styles['img-carousel']} >
                <div className={styles["img-text"]}>
                </div>
                    <Image src={"https://res.cloudinary.com/dlwdq84ig/image/upload/v1730567931/xdc2iyjg17bpi0ja7qpp.webp"} width={504} height={201} alt="Banner1" loading='lazy' loader={imageLoader}/>
            </div>
            </Link>
          </Carousel.Item> */}
          {/* <Carousel.Item>
            <div className={styles['img-carousel']} >
              <div className={styles["img-text"]}>
              </div>
              <Image src={"https://res.cloudinary.com/dlwdq84ig/image/upload/v1734995156/hpznfjbpn839pbaazfut.png"} width={504} height={201} alt="Banner1" loading='lazy' loader={imageLoader}/>
            </div>
          </Carousel.Item> 
          <Carousel.Item>
            <div className={styles['img-carousel']} >
              <div className={styles["img-text"]}>
              </div>
              <Image src={"https://res.cloudinary.com/dlwdq84ig/image/upload/v1730568112/sopmfq1kvc5ss3ypneal.webp"} width={504} height={201} alt="Banner1" loading='lazy' loader={imageLoader}/>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className={styles['img-carousel']} >
              <div className={styles["img-text"]}>
              </div>
              <Image src={"https://res.cloudinary.com/dlwdq84ig/image/upload/v1730567811/ztbdvveoubbz46xyhqku.webp"} width={504} height={201} alt="Banner1" loading='lazy' loader={imageLoader}/>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className={styles['img-carousel']} >
              <div className={styles["img-text"]}>
              <p>¡Descarga tu manual!</p>
                <p>Descarga el manual de
                  uso del nuevo portal
                  desde nuestra sección
                  de comunicados.
                </p>
                <Link href={CONST_REDIRECTS.ANNOUNCEMENTS} ><div>
                  <span style={{color:"white",fontSize:"0.7rem",textDecoration:"underline",cursor:"pointer"}}>¡Explora ahora!</span></div>
                </Link>
              </div>
              <Image src={"https://res.cloudinary.com/dlwdq84ig/image/upload/v1711561038/jmram1cqwk7a1kf5ahjz.webp"} width={504} height={201} alt="Banner1" loading='lazy' loader={imageLoader}/>
            </div>
          </Carousel.Item>
        </Carousel>
      </div> */}
        </Carousel>
      </div>
    </>
  );
};

export default CarouselB2b;
