import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import styles from './Slider.module.css';
import stylesModal from '../plp/styles.module.scss';
import { INewProducts } from '@/shared/interfaces/IProduct';
import Link from 'next/link';
import { rol } from '@/shared/roles/featureFlags';
import { Customer } from '@commercetools/platform-sdk';
import {
    addProductToCartWithShippingAddress,
    sdk_loadMyBusinessUnit,
    ICustomBusinessUnit,
} from '@/shared/functions/commerceTools';
import { notifyError, notifySuccess } from '../toast/Toast';
import { useRouter } from 'next/router';
import ProductButton from '../plp/ProductButton';
import Modal from '@/shared/components/modal/Modal';
import { useForm } from 'react-hook-form';
import CustomDropdown from '@/shared/components/customDropdown/CustomDropdown';
import { getStandalonePriceByBranch } from '@/shared/functions/commerceTools';
import ButtonAdd from '@/shared/components/buttonAdd/ButtonAdd';
import Button from '@/shared/components/button/Button';
import { formatCurrency } from '@/shared/functions/currencies';
import { AppContext } from 'context/appContext/AppContextProvider';

export const Slider = ({
    title,
    items,
    buttonTitle,
    scrollAmount = 100,
    sliderRef,
    style,
    variant = 'none',
    customer,
}: {
    title: string;
    items: INewProducts[];
    buttonTitle: string;
    scrollAmount?: number;
    sliderRef: React.MutableRefObject<HTMLParagraphElement | null>;
    style?: CSSProperties;
    variant?: 'none' | 'link' | 'button';
    customer: Customer | null;
}) => {
    const router = useRouter();
    const prevItem = () => {
        const container = sliderRef.current;
        if (container) {
            container.scrollLeft -= scrollAmount;
        }
    };

    const context = useContext(AppContext);

    const nextItem = () => {
        const container = sliderRef.current;
        if (container) {
            container.scrollLeft += scrollAmount;
        }
    };

    const handleClick = async (item: INewProducts) => {
        if (!customer?.custom?.fields.roles) return;

        if (rol[customer.custom.fields.roles]['createOrders'] && rol[customer.custom.fields.roles]['haveBranches']) {
            // dirigir a pdp
            router.push('/pdp?productId=' + item.id + '&variantId=' + item.variantId + '&productKey=' + item.productKey);
            return;
        }
        if (rol[customer.custom.fields.roles]['createOrders']) {
            // añadir a carrito
            const resMyBusinessUnit = await sdk_loadMyBusinessUnit();
            let itemVariantIdNumber: number = 0;
            if (typeof item.variantId === 'number') {
                itemVariantIdNumber = item.variantId;
            }
            if (typeof item.variantId === 'string') {
                itemVariantIdNumber = parseInt(item.variantId, 10);
            }
            //si no se entro a ningun caso item.variantId fue undefined
            if (!itemVariantIdNumber) return;
            const resAddProduct = await addProductToCartWithShippingAddress({
                quantity: 1,
                variantId: itemVariantIdNumber,
                sku: item.sku,
                addressKey: resMyBusinessUnit.shippingAdressId,
            });
            if (!resAddProduct.wentOk) {
                notifyError('hubo un problema al agregar el producto al carrito :(');
                return;
            }
            notifySuccess('se agrego el producto al carrito');
            router.reload();
        }
        if (!rol[customer.custom.fields.roles]['createOrders']) {
            notifyError('no cuentas con los permisos necesarios para realizar esta acción');
        }
    };
    type FormValues = {
        unitBusiness: string;
    };
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedProductToAdd, setSelectedProductToAdd] = useState<any>({ priceCents: 0 });
    const [selectedProduct, setSelectedProduct] = useState<INewProducts | null>(null);
    const [currentImg, setCurrentImg] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const {
        handleSubmit,
        formState: { errors },
        watch,
        setError,
        clearErrors,
    } = useForm<FormValues>();
    const onSubmit = (formValues: FormValues) => { };

    const handleSubmitProductsToCart = async () => {
        if (isLoading) return;
        // getCart(props.product);
        if (rol[customer?.custom?.fields.roles] && !rol[customer?.custom?.fields.roles]['createOrders']) {
            notifyError('no cuentas con los permisos necesarios para crear una compra');
            return;
        }

        const addressKey = selectedProductToAdd?.addressKey;
        if (!addressKey) {
            notifyError('No se ha seleccionado una sucursal');
            return;
        }

        setIsLoading(true);
        //@ts-ignore
        const resAddProduct = await addProductToCartWithShippingAddress({
            quantity,
            sku: selectedProductToAdd.sku,
            addressKey: selectedProductToAdd.addressKey,
        });

        if (!resAddProduct.cart) {
            notifyError('error al cargar producto a carrito,' + resAddProduct.errMessage);
            return;
        }

        notifySuccess('Se agrego correctamente');
        setIsLoading(false);

        // router.reload();
        context.setCart(resAddProduct.cart);
        setShowModal(false);
        setSelectedProductToAdd({});
    };

    const [minimalquantity, setMinimalquantity] = useState<number>(1);
    const [incremental, setIncremental] = useState<number>(1);
        //min-max
        useEffect(()=>{
            if(!selectedProduct) return
            
            console.log("sp",selectedProduct)
            const incremental= selectedProduct.attributes.find((attr:any)=>attr.name==="incremental")?.value || 1
            const minimalquantity= selectedProduct.attributes.find((attr:any)=>attr.name==="minimalquantity")?.value || 1
    
            setMinimalquantity(minimalquantity)
            setIncremental(incremental)
            setQuantity(minimalquantity)
        },[selectedProduct])

    return (
        <div className={styles['container-slider']} style={style}>
            <div className={styles['slider']}>
                <p className={styles['items-title']}>{title}</p>
                <div className={styles['items-container']}>
                    <div className={styles['arrow-left']}>
                        <img src="/arrowLeft.svg" onClick={prevItem} />
                    </div>
                    <div className={styles['items']} ref={sliderRef}>
                        {items?.map((item) => (
                            <div className={styles['item-container']} key={item.sku}>
                                <div className={styles['item-container-description']}>
                                    <img height={73} src={item.image || "/no_econtrada.webp"} width={73} />
                                    <div className={styles['item-info']}>
                                        <div className={styles['item-title']}>{item.name}</div>
                                        

                                        <p className={styles['item-decription']}>
                                          <div >SKU: {item.sku}</div>
                                            <Link
                                                href={`/pdp?productId=${item.id}&variantId=${item.variantId}&productKey=${item.productKey}`}
                                            >
                                                Ver detalle
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                                {customer && (
                                    <ProductButton
                                        selectedProduct={selectedProduct}
                                        setQuantityRelated={setQuantity}
                                        customer={customer}
                                        setShowModal={setShowModal}
                                        setSelectedProduct={setSelectedProduct}
                                        product={item}
                                        branch={context.appContext.myBussinessUnit}
                                        setIsLoading={setIsLoading}
                                        isLoading={isLoading}
                                        buttonLabel='Agregar'
                                    />
                                )}
                            </div>
                        )) ?? []}
                    </div>
                    <div className={styles['arrow-right']}>
                        <img src="/arrowRight.svg" onClick={nextItem} />
                    </div>
                </div>
            </div>

            <div className={stylesModal['modal-container']}>
                <Modal
                    label1="Asigna una sucursal y cantidad para este producto"
                    open={showModal}
                    onClose={() => {
                        setShowModal(false);
                        setSelectedProductToAdd({});
                    }}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={stylesModal['form-container']}>
                            <div className={stylesModal['product-flex']}>
                                <img
                                    key={selectedProduct?.id}
                                    alt="product"
                                    src={selectedProduct?.image}
                                    className={stylesModal['item-img']}
                                />

                                {/* Información del producto */}
                                {selectedProduct && (
                                    <div className={stylesModal['product-info']}>
                                        <p className={stylesModal['text-info']}>{selectedProduct?.name}</p>
                                        {selectedProductToAdd.priceCents ? (
                                            <p className={stylesModal['text-price']}>
                                                {formatCurrency(selectedProductToAdd.priceCents / 100)}
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div className={stylesModal['flex-items']}>
                                <div style={{ width: '200px', height: '20px' }}>
                                    <CustomDropdown
                                        className={stylesModal['add-sucursal']}
                                        onChange={async (e: any) => {
                                            const keyBussinessUnit = selectedProduct?.listBussiness.find(
                                                (bu: any) => e.target.value === bu.value,
                                            )?.value || "key";
                                            const resStandalonePrice = await getStandalonePriceByBranch({
                                                sku: selectedProduct?.sku || "sku",
                                                keyBussinessUnit,
                                                distributionChannel: 'general',
                                            });

                                            setSelectedProductToAdd({
                                                addressKey: e.target.value,
                                                sku: selectedProduct?.sku ||"sku",
                                                product: selectedProduct,
                                                priceCents: resStandalonePrice.body.value.centAmount,
                                            });
                                        }}
                                        items={selectedProduct?.listBussiness || []}
                                        name={'addSucursal-' + selectedProduct?.id}
                                        label="Seleccionar sucursal"
                                    />
                                </div>
                                <div className={stylesModal['item-modal-add']}>
                                    <ButtonAdd
                                        quantity={quantity}
                                        onAdd={() => {
                                            setQuantity(quantity + incremental);
                                        }}
                                        onRemove={() => {

                                      setQuantity(Math.max(minimalquantity,quantity - incremental));


                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                            <Button
                                onClick={handleSubmitProductsToCart}
                                variant={isLoading ? 'disabled' : 'secondary'}
                                style={{ height: '40px', width: '416px' }}
                                type="submit"
                            >
                                Agregar a carrito
                            </Button>
                        </div>
                    </form>
                </Modal>
            </div>
        </div>
    );
};
